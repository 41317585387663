a.goal-container {
  @apply max-w-lwd-197 cursor-pointer;
}

.goal-container {
  @apply max-w-lwd-197 w-full;
}

@media screen and (max-width: 757px) and (min-width: 500px) {
  .goal-container {
    @apply justify-self-center;
  }
}

.goal-image .image-container img {
  @apply rounded-none rounded-t-xl;
}

.goal-image {
  @apply h-lwd-105 bg-primary-100 rounded-t-xl flex justify-center items-center;
}

.goal-text {
  @apply py-2 bg-lwd-grey-100 rounded-b-xl font-todaysbmed text-20 leading-normal tracking-lwd-0.5 text-center;
}
