.control-container {
  @apply flex items-center gap-4;
}

.control-container label {
  @apply text-18 leading-normal tracking-wide cursor-pointer;
}

.control-container label em {
  @apply not-italic text-error-500 ml-2;
}

input[type="checkbox"] {
  @apply appearance-none h-8 w-8 border-2 border-primary-300 rounded-md bg-white   hover:bg-primary-200 hover:ring-7 hover:ring-primary-200 hover:border-primary-500   focus:outline-none focus:bg-primary-200 focus:ring-7 focus:ring-primary-200 focus:ring-offset-transparent focus:border-primary-500   transition duration-200 cursor-pointer;
}

input[type="checkbox"]:disabled,
input[type="radio"]:disabled {
  @apply border-lwd-grey-600;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
  @apply bg-primary-500 hover:bg-primary-800 hover:ring-0 hover:ring-transparent focus:bg-primary-500;
}

input[type="checkbox"]:checked:disabled,
input[type="radio"]:checked:disabled {
  @apply border-lwd-grey-700 bg-lwd-grey-700;
}

input[type="radio"] {
  @apply appearance-none h-8 w-8 border-2 border-primary-500 bg-white   hover:bg-primary-200 hover:ring-7 hover:ring-primary-200   focus:outline-none focus:bg-primary-200 focus:ring-7 focus:ring-primary-200 focus:ring-offset-transparent transition duration-200 cursor-pointer;
}

@media only screen and (hover: none) and (pointer: coarse) {
  input[type="checkbox"], input[type="radio"] {
    @apply hover:bg-transparent hover:ring-0 hover:ring-transparent focus:outline-none focus:bg-transparent focus:ring-0 focus:ring-transparent focus:ring-offset-transparent;
  }
}