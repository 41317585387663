.radio-group p {
  @apply mb-2;
}

.radio-group p em {
  @apply not-italic text-error-500 pl-2;
}

.radio-group .radios {
  @apply flex flex-col gap-3;
}

.radio-group .radios .input-container .input-top {
  @apply mb-0;
}

.radio-group-container {
  @apply flex flex-col gap-4;
}

.radio-group-container label {
  @apply flex gap-4 items-center text-18 leading-normal tracking-wide;
}

.radio-group-container label.extrastyle:hover,
.radio-group-container label.extrastyle:active {
  @apply border-primary-500;
}

.radio-group-container input[type="checkbox"] {
  @apply appearance-none h-8 w-8 border-2 border-primary-500 rounded-md bg-white   hover:bg-primary-200 hover:ring-7 hover:ring-primary-200   focus:outline-none focus:bg-primary-200 focus:ring-7 focus:ring-primary-200 focus:ring-offset-transparent transition duration-200 cursor-pointer;
}
.radio-group-container input[type="checkbox"]:disabled,
.radio-group-container input[type="radio"]:disabled {
  @apply border-lwd-grey-600;
}

.radio-group-container input[type="checkbox"]:checked,
.radio-group-container input[type="radio"]:checked {
  @apply bg-primary-500 hover:bg-primary-800 hover:ring-0 hover:ring-transparent focus:bg-primary-500;
}
.radio-group-container input[type="checkbox"]:checked:disabled,
.radio-group-container input[type="radio"]:checked:disabled {
  @apply border-lwd-grey-700 bg-lwd-grey-700;
}

.radio-group-container input[type="radio"] {
  @apply appearance-none h-8 w-8 border-2 border-primary-500 bg-white   hover:bg-primary-200 hover:ring-7 hover:ring-primary-200   focus:outline-none focus:bg-primary-200 focus:ring-7 focus:ring-primary-200 focus:ring-offset-transparent transition duration-200 cursor-pointer;
}

.radio-group-container.emoji-radio-group {
  @apply flex-row;
}

.radio-group-container.emoji-radio-group input[type="radio"] {
  @apply block m-0 w-0 h-0 opacity-0 text-0 border-0;
}

.radio-group-container.emoji-radio-group label {
  @apply text-33 gap-0 justify-center border-3 border-solid border-transparent rounded-full;
}

.radio-group-container.emoji-radio-group label span {
  @apply flex items-center justify-center w-lwd-30 h-lwd-30 pl-px pt-1.5;
}

.radio-group-container.emoji-radio-group label.checked {
  @apply border-secondary-500;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .radio-group-container input[type="checkbox"],
  .radio-group-container input[type="radio"] {
    @apply hover:bg-transparent hover:ring-0 hover:ring-transparent focus:outline-none focus:bg-transparent focus:ring-0 focus:ring-transparent focus:ring-offset-transparent;
  }
}