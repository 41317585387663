.dTitle-container {
  @apply py-lwd-30 px-6;
}

.slick-list {
  padding: 0 2rem 1rem 0 !important;
}

.slick-track {
  display: flex !important;
}

.card-carousel * {
  min-width: 0;
  min-height: 0;
}

.card-carousel-container .card-carousel .simple-card {
  @apply max-w-none;
}

.slick-slide {
  display: flex !important;
  height: inherit !important;
}

.slick-slide > div {
  @apply flex flex-col w-full grow;
}

.slick-slide > div .carousel-item {
  @apply w-full grow;
}

.yoga-image-container {
  @apply w-8/12 aspect-auto mx-auto flex smd:w-full;
}

.steps {
  @apply flex flex-col gap-6 items-center;
}
