.rating-container label {
  @apply font-todaysbmed text-18 tracking-lwd-0.5 md:text-20;
}

.rating-container label em {
  @apply not-italic text-error-500 ml-2;
}

/****** Style Star Rating Widget *****/
.rating {
  border: none;
}

.rating > input {
  display: none;
}
.rating > label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: FontAwesome;
  display: inline-block;
  /* content: "\f005"; */
  content: url("../../../assets/icons/empty-purple-star.svg");
}

@media only screen and (max-width: 640px){
  .rating > label:before {
    margin: 3px;
  }
}

.rating > .half:before {
  content: "\f089";
  position: absolute;
}

.rating > label {
  color: #ddd;
  float: right;
}

/***** Highlight Stars on Hover *****/

.rating > input:checked ~ label:before, /* show gold star when clicked */
.rating:not(:checked) > label:hover:before, /* hover current star */
.rating:not(:checked) > label:hover ~ label:before {
  /* color: #ffd700; */
  content: url("../../../assets/icons/full-purple-star.svg");
} /* hover previous stars in list */

