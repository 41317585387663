#gp_surgery.react-select,
#where_work.react-select {
  text-transform: lowercase;
}

#gp_surgery.react-select .react-select__single-value:first-line,
#where_work.react-select .react-select__single-value:first-line {
  text-transform: capitalize;
}

#gp_surgery.react-select .react-select__option:first-line,
#where_work.react-select .react-select__option:first-line {
  text-transform: capitalize;
}

#gp_surgery.react-select.react-select__placeholder,
#where_work.react-select.react-select__placeholder {
  text-transform: none;
}