.input-container {
  @apply flex flex-col w-full;
}

.input-container label {
  @apply font-todaysbmed text-18 tracking-lwd-0.5 md:text-20;
}

/* Make label 'sentence case' */
/* Note: affect labels above all inputs, labels inside checkboxs and labels inside radios */
.input-container .input-top label, .input-container .field-control .control-container label, .input-container .radio-group-container label span {
  /* @apply lowercase; */
}
/* .input-container .input-top label::first-letter, .input-container .field-control .control-container label::first-letter, .input-container .radio-group-container label span::first-letter {
  @apply capitalize;
} */

/* Note: shadow-none is for .react-select__control */
.input-container input[type="text"],
.input-container input[type="email"],
.input-container input[type="tel"],
.input-container input[type="password"],
.input-container input[type="number"],
.input-container textarea,
.input-container .react-select__control {
  @apply relative border-2 border-primary-300 rounded-xl w-full px-4 py-lwd-14.5 text-18 leading-normal tracking-wide shadow-none focus:ring-0;
}

.input-container input:not([value=""]),
.input-container textarea.has-contents {
  @apply border-lwd-grey-800;
}

.input-container input[type="text"]:hover,
.input-container input[type="text"]:active,
.input-container input[type="email"]:hover,
.input-container input[type="email"]:active,
.input-container input[type="tel"]:hover,
.input-container input[type="tel"]:active,
.input-container input[type="password"]:hover,
.input-container input[type="password"]:active,
.input-container input[type="number"]:hover,
.input-container input[type="number"]:active,
.input-container textarea:hover,
.input-container textarea:active,
.input-container select:hover,
.input-container select:active,
.input-container .react-select__control:hover,
.input-container .react-select__control:active {
  @apply border-primary-500;
}

.input-container input.error,
.input-container input.error:hover,
.input-container input.error:active,
.input-container .error .react-select__control {
  @apply border-error-500;
}

.input-container input:disabled,
.input-container input:disabled:hover,
.input-container input:disabled:active,
.input-container textarea:disabled,
.input-container textarea:disabled:hover,
.input-container textarea:disabled:active,
.input-container .react-select__control--is-disabled {
  @apply bg-lwd-grey-200 border-lwd-grey-700;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .input-container input[type="text"]:hover,
  .input-container input[type="text"]:active,
  .input-container input[type="email"]:hover,
  .input-container input[type="email"]:active,
  .input-container input[type="tel"]:hover,
  .input-container input[type="tel"]:active,
  .input-container input[type="password"]:hover,
  .input-container input[type="password"]:active,
  .input-container input[type="number"]:hover,
  .input-container input[type="number"]:active,
  .input-container textarea:hover,
  .input-container textarea:active,
  .input-container select:hover,
  .input-container select:active,
  .input-container .react-select__control:hover,
  .input-container .react-select__control:active {
    @apply border-primary-300;
  }

  .input-container input.error:hover,
  .input-container input.error:active {
    @apply border-error-500;
  }

  .input-container input:disabled:hover,
  .input-container input:disabled:active,
  .input-container textarea:disabled:hover,
  .input-container textarea:disabled:active {
    @apply bg-lwd-grey-200 border-lwd-grey-700;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.input-container textarea {
  resize: none;
}

.input-container input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  @apply text-lwd-grey-700 text-18 tracking-wide;
}
.input-container input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  @apply text-lwd-grey-700 text-18 tracking-wide;
}
.input-container input::-ms-input-placeholder {
  /* Microsoft Edge */
  @apply text-lwd-grey-700 text-18 tracking-wide;
}

.input-container .react-select:not(.searchable) .react-select__control .react-select__input-container {
  /* display: none !important; */
  @apply absolute top-0 left-0 m-0 py-0;
}

/***** input-top /*****/

.input-container .input-top {
  @apply flex items-center mb-2;
}

.input-container .input-top em {
  @apply not-italic text-error-500 ml-2;
}

.input-container .input-top .help-icon {
  @apply inline-block translate-y-1 ml-2;
}

.input-container .input-top .help-icon svg {
  @apply w-lwd-22 h-lwd-22;
}

/***** field-control /*****/

.input-container .field-control.dobstyle div:first-of-type {
  @apply flex;
}

.input-container .field-control.extrastyle {
  @apply border-2 border-primary-300 rounded-xl w-full p-4 cursor-pointer;
}

.input-container .field-control.checkedstyle {
  @apply border-primary-500;
}

/***** input-bottom *****/

.input-container .input-bottom .input-hint {
  @apply flex items-center gap-4;
}

.input-container .input-bottom .input-hint svg {
  @apply w-6;
}

.input-container .input-bottom .input-hint p {
  @apply text-lwd-grey-800 text-18 tracking-wide leading-normal;
}

.input-container .info-card-container {
  @apply mt-2;
}

/***** react-select *****/
.react-select {
  @apply w-full;
}

.input-container .react-select__value-container {
  @apply p-0;
}

.input-container .react-select__placeholder {
  @apply m-0 text-lwd-grey-700 normal-case;
}

.input-container .react-select__indicator-separator {
  @apply w-0;
}

.input-container .react-select__dropdown-indicator {
  @apply text-lwd-grey-900;
}

.input-container .react-select__indicator {
  @apply p-0;
}

.input-container .react-select__control--is-focus {
  @apply ring-0;
}

.input-container .has-selected .react-select__control {
  @apply border-lwd-grey-800;
}

.input-container .react-select__control--menu-is-open {
  @apply rounded-b-none border-primary-500;
}

.input-container .react-select__menu,
.react-select__menu-portal .react-select__menu {
  @apply m-0 shadow-none border border-t-0 border-primary-500 border-solid rounded-t-none rounded-b-xl z-20;
}

.react-select__menu-portal .react-select__menu {
  @apply overflow-hidden;
}

.input-container .error .react-select__menu {
  @apply border-error-500;
}

.input-container .react-select__menu-list,
.react-select__menu-portal .react-select__menu .react-select__menu-list {
  @apply flex flex-col p-0 rounded-b-xl;
}

.input-container .react-select__option,
.react-select__menu-portal .react-select__option {
  @apply text-18 text-lwd-grey-900 leading-normal border-b-2 border-solid border-primary-500 p-4;
}

.input-container .react-select__menu-list .react-select__menu-notice.react-select__menu-notice--no-options {
  @apply text-18 leading-normal tracking-wide text-lwd-grey-700;
}

.input-container .react-select__option:last-of-type,
.react-select__menu-portal .react-select__option:last-of-type {
  @apply border-b-0 rounded-b-xl;
}

.input-container .react-select__option,
.react-select__menu-portal .react-select__option {
  @apply bg-lwd-grey-100;
}

.input-container .error .react-select__option {
  @apply border-error-500;
}

.input-container .react-select__option--is-focused,
.react-select__menu-portal .react-select__option--is-focused {
  @apply bg-primary-100;
}

.input-container .react-select__option--is-selected,
.react-select__menu-portal .react-select__option--is-selected {
  @apply bg-primary-500 text-lwd-grey-100;
}

.input-container .react-select__option--is-disabled,
.react-select__menu-portal .react-select__option--is-disabled {
  @apply text-lwd-grey-900-45 bg-lwd-grey-900-10 line-through;
}
