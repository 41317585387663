@font-face {
  font-family: TodaySB;
  src: url("../src/assets/fonts/TodaySB-Regular.otf");
}
@font-face {
  font-family: TodaySBMed;
  src: url("../src/assets/fonts/TodaySB-Medium.otf");
}
@font-face {
  font-family: TodaySBBold;
  src: url("../src/assets/fonts/TodaySB-Bold.otf");
}

/****** for Star Rating Widget *****/
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

html {
  --font-sans-serif: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}

body {
  font-family: TodaySB, var(--font-sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: auto;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
